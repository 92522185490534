.scrollable-content {
    max-height: 80vh; /* Ajusta esta altura según tus necesidades */
    overflow-y: auto;
    padding-bottom: 6rem !important;
    padding: 0;
}
.react-modal-sheet-container {
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
}
