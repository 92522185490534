.my-position {
  position: relative;
  cursor: pointer;
  pointer-events: none;
}

.animation {
  position: absolute;
  top: 50%;
  left: 50%;
  animation: moveUpDown 2s ease-in-out infinite;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(7px);
  }
  100% {
    transform: translateY(0);
  }
}
