@import url('./variables.scss');
@import "~react-image-gallery/styles/scss/image-gallery.scss";
html  {
  font-family: 'Poppins', sans-serif !important;
}

/* .MuiTabs-indicator {
  background-color: #e17a5e !important;
}
.css-1dn1qn3-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  color: #e17a5e!important;
} */

.pulse-marker {
  position: relative;
  animation: pulse3 2s infinite;
  border-radius: 50%;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    top: 0;
  }
  50% {
    opacity: 1;
    top: 10px; /* Ajusta la distancia que se moverá hacia abajo */
  }
}


@keyframes pulse2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse3 {
  0% {
    box-shadow: 0 0 0 0 rgba(61, 157, 227, 0.5);
  }
  50% {
    box-shadow: 0 0 40px 40px rgba(61, 157, 227, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(61, 157, 227, 0);
  }
}

.css-yevbmx-MuiModal-root-MuiDialog-root {
  background-color: black !important
}

.fade-in-text {
  animation: fadeIn 5s ease-in-out forwards;
}