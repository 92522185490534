.custom-marker-place-visited {
   
    border: 4px solid  rgb(255 255 255 / 25%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #4B267C;

  }

  .custom-marker-place-not-visited {
    border: 4px solid #4B267C;/* rgba(197, 0, 0, 0.25) */;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #FFF;
    animation: shadowPulse 2s infinite;
  }

  @keyframes shadowPulse {
    0% {
      box-shadow: 0 0 4px FFF;
    }
    50% {
      box-shadow: 0 0 8px #4b267d;
    }
    100% {
      box-shadow: 0 0 4px FFF;
    }
  }


  .visited {
   /*  background: #4B267C;
    width: 40px;
    height: 40px;
    z-index: 50; */
  }
